@font-face {
  font-family: 'Didact Gothic';
  src: url('./fonts/DidactGothic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: 'League Gothic';
  src: url('./fonts/leaguegothic-regular-webfont.eot');
  src: url('./fonts/leaguegothic-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/leaguegothic-regular-webfont.woff') format('woff'),
       url('./fonts/leaguegothic-regular-webfont.ttf') format('truetype'),
       url('./fonts/leaguegothic-regular-webfont.svg#league_gothicregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

/* Italic */
@font-face {
  font-family: 'League Gothic Italic';
  src: url('./fonts/leaguegothic-italic-webfont.eot');
  src: url('./fonts/leaguegothic-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/leaguegothic-italic-webfont.woff') format('woff'),
       url('./fonts/leaguegothic-italic-webfont.ttf') format('truetype'),
       url('./fonts/leaguegothic-italic-webfont.svg#league_gothic_italicregular') format('svg');
  font-weight: normal;
  font-style: italic;

}

/* Condensed */
@font-face {
  font-family: 'League Gothic Condensed';
  src: url('./fonts/leaguegothic-condensed-regular-webfont.eot');
  src: url('./fonts/leaguegothic-condensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/leaguegothic-condensed-regular-webfont.woff') format('woff'),
       url('./fonts/leaguegothic-condensed-regular-webfont.ttf') format('truetype'),
       url('./fonts/leaguegothic-condensed-regular-webfont.svg#league_gothic_condensed-Rg') format('svg');
  font-weight: normal;
  font-style: normal;

}

/* Condensed Italic */
@font-face {
  font-family: 'League Gothic Condensed Italic';
  src: url('./fonts/leaguegothic-condensed-italic-webfont.eot');
  src: url('./fonts/leaguegothic-condensed-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/leaguegothic-condensed-italic-webfont.woff') format('woff'),
       url('./fonts/leaguegothic-condensed-italic-webfont.ttf') format('truetype'),
       url('./fonts/leaguegothic-condensed-italic-webfont.svg#league_gothic_condensed_itaRg') format('svg');
  font-weight: normal;
  font-style: italic;
}
